<template>
  <section class="content">
    <div class="btn-box">
      <el-form :inline="true">
        <el-form-item label="itemid：">
          <el-input v-model="itemid" placeholder="请输入itmeid"></el-input>
        </el-form-item>
        <el-form-item label="user_id：">
          <el-input v-model="uid" placeholder="请输入user_id"></el-input>
        </el-form-item>
        <el-form-item label="兑换筛选：">
          <el-date-picker
            v-model="time"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="seach">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="recordList" border>
      <el-table-column fixed prop="id" label="编号" width="150px"> </el-table-column>
      <el-table-column fixed prop="item_free_id" label="免单商品表id" width="150px">
      </el-table-column>
      <el-table-column fixed label="uid" width="200">
        <template slot-scope="scope">
          {{ scope.row.user_id }}({{ scope.row.user_key }})
        </template>
</el-table-column>
<el-table-column label="itemid" prop="item_id"></el-table-column>
<el-table-column label="商品主图">
    <template slot-scope="scope">
          <el-image
            :src="scope.row.item_pic"
            :preview-src-list="[scope.row.item_pic]"
          ></el-image>
        </template>
</el-table-column>
<el-table-column prop="item_title" label="商品标题" width="250"></el-table-column>
<el-table-column prop="end_price" label="券后价" width="100px"></el-table-column>
<el-table-column label="兑换时间">
    <template slot-scope="scope">
          {{ scope.row.click_time | dateTime }}
        </template>
</el-table-column>
<el-table-column label="消耗免单点" prop="free_order_point"></el-table-column>
<el-table-column label="订单状态">
    <template slot-scope="scope">
          <span
            :class="
              scope.row.status == 1
                ? 'warn'
                : scope.row.status == 3
                ? 'blue'
                : scope.row.status == 4
                ? 'red'
                : ''
            "
          >
            {{ scope.row.status | status }}
          </span>
        </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>
</section>
<!-- /.content -->
</template>

<script>
    import * as api from "@/config/api";
    export default {
        name: "record",
        components: {},

        data() {
            let date = new Date().getTime();
            let start = new Date().getTime() - 86400000 * 14;
            return {
                time: [start, date],
                recordList: [],
                total: 0,
                page: 1,
                pagesize: 10,
                uid: "",
                itemid: "",
                loading: true,
            };
        },
        watch: {
            time(v, n) {
                console.log(v, n)
            }
        },
        filters: {
            status(val) {
                let name;
                if (val == 1) {
                    name = "未下单";
                } else if (val == 2) {
                    name = "已下单";
                } else if (val == 3) {
                    name = "已失效";
                } else {
                    name = "未知";
                }
                return name;
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000);
                if (Number(val) === 0) {
                    return "";
                }
                return (
                    day.getFullYear() + "-" + `${day.getMonth() + 1 < 10? "0" + (day.getMonth() + 1): day.getMonth() + 1}` + "-" + `${day.getDate() < 10 ? "0" + day.getDate() : day.getDate()}` + " " + `${day.getHours() < 10 ? "0" + day.getHours() : day.getHours()}` + ":" + `${day.getMinutes() < 10 ? "0" + day.getMinutes() : day.getMinutes()}` + ":" + `${day.getSeconds() < 10 ? "0" + day.getSeconds() : day.getSeconds()}`
                );
            },
        },
        mounted() {
            this.getRecordList();
        },
        methods: {
            close() {
                this.show = false;
                this.getRecordList();
            },
            getDateMYD(date) {
                let nd = new Date(date);
                let y = nd.getFullYear();
                let m = nd.getMonth() + 1;
                m = m < 10 ? '0' + m : m;
                let r = nd.getDate();
                r = r < 10 ? '0' + r : r;
                return `${y}-${m}-${r}`;
            },
            getRecordList(page, size) {
                this.loading = true;
                let start_time = this.time ? this.getDateMYD(this.time[0]) : '';
                let end_time = this.time ? this.getDateMYD(this.time[1]) : '';
                api.getItemFreeRecords({
                        user_id: this.uid,
                        item_id: this.itemid,
                        page: page || this.page,
                        pagesize: size || this.pagesize,
                        start_time,
                        end_time,
                    },
                    (res) => {
                        this.recordList = res.data.list || [];
                        this.total = Number(res.data.count);
                        this.loading = false;
                    }
                );
            },
            sizeChange(val) {
                this.pagesize = val;
                this.getRecordList();
            },
            currentChange(val) {
                this.page = val;
                this.getRecordList();
            },
            seach() {
                this.page = 1;
                this.getRecordList();
            },
        },
    };
</script>
<style scoped>
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        height: 40px;
    }
    
    .red {
        color: #f56c6c;
    }
    
    .blue {
        color: #409eff;
    }
    
    .green {
        color: #67c23a;
    }
    
    .warn {
        color: #e6a23c;
    }
</style>